import React from 'react'
import { useTranslation } from 'react-i18next'

import LayoutContent from '../components/layout/layoutContent'

const StatsGonePage = props => {
    const { t } = useTranslation()

    return (
        <LayoutContent {...props} hasVerticalGutters>
            <h1>{t('Gone')}</h1>
            <p>{t('Looking for stats? Relive your Gears history and review your data in-game. We’ve retired all Gears of War legacy title’s card and stats pages. They served the COG well.')}</p>
        </LayoutContent>
    )
}

export default StatsGonePage
